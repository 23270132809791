// Here you can add other styles
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .card,
.c-dark-theme .c-app:not(.c-legacy-theme) .card,
.c-dark-theme .card{
    border-radius: 10px;
    box-shadow: 0 24px 30px #2824491a;
}
.card-header,
.c-dark-theme .card-header{
    background: none;
    border: none;
    font-weight: 600;
}
.c-app{
    background-color: #f5f5f5;
    border: none;
}
.btn-primary,
.c-dark-theme .btn-primary,
.modal-primary .modal-header,
.c-dark-theme .modal-primary .modal-header,
.page-item.active .page-link
{
    background-color: #00adee;
}
.page-item.active .page-link{
    border-color: #00adee;
}
.btn-primary:hover,
.c-dark-theme .btn-primary:hover
{
    background-color: #00adee;
    box-shadow: 0 24px 30px #2824491a;
}
.btn-primary.disabled, .btn-primary:disabled{
    background-color: #37b2df;
}
.c-app.c-dark-theme{
    background-color: #1d1d22;
}
.c-dark-theme .card,
.c-dark-theme .c-sidebar,
.c-dark-theme .c-header,
.c-dark-theme .c-header .c-subheader{
    background-color: #35353a;
}
.btn-outline-primary,
.c-dark-theme .btn-outline-primary
{
    color: #00adee;
    border-color: #00adee;
}
.btn-outline-primary:hover,
.c-dark-theme .btn-outline-primary:hover
{
    background-color: #00adee;
    border-color: #00adee;
}
.c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-header.c-header-fixed {
    box-shadow: 0 5px 10px #78787a1a;
}
.text-primary,
.c-dark-theme .text-primary
{
    color: #00adee !important;
}
a,
a:hover,
.c-dark-theme a,
.c-dark-theme a:hover
{
    color: #00adee;
}
.c-sidebar-nav-link:hover{
    background: #00adee !important;
}
.c-footer{
    background-color: #dfdfdf;
    border: none;
}
.c-sidebar{
    background: #072b4d;
}